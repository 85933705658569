import { Ref, forwardRef } from 'react';
import Image from 'next/image';
import clsx from 'clsx';
type Logo = {
  logoImage?: {
    url: string;
    dimensions: {
      width: number;
      height: number;
    };
  };
  logoAlt: string;
  logoWidth: number;
  link?: {
    href: string;
    target?: string;
  };
};
type Props = {
  className?: string;
  logos: Logo[];
  gap?: number;
};
export const LogoGrid = forwardRef(function LogoGrid({
  className,
  logos,
  gap
}: Props, ref: Ref<HTMLDivElement>) {
  return <div ref={ref} className={clsx(className, '@container')}>
			<div className="flex flex-wrap justify-start items-center" style={{
      rowGap: gap ? gap : undefined
    }}>
				{logos.length === 0 ? <p className="text py-4 text-center text-gray-700">There are no logos</p> : logos.map(({
        logoImage,
        logoAlt,
        logoWidth,
        link
      }, index) => {
        return <div key={index} className={clsx('shrink-0 basis-1/2', logos.length === 2 && '@xl:basis-1/2', logos.length === 3 && '@xl:basis-1/3 @xs:basis-1/2', logos.length === 4 && '@xl:basis-1/4 @sm:basis-1/3 @xs:basis-1/2', logos.length > 4 && '@xl:basis-1/5 @sm:basis-1/3 @xs:basis-1/2')} style={{
          paddingLeft: gap ? gap / 2 : undefined,
          paddingRight: gap ? gap / 2 : undefined
        }}>
								<div className="flex justify-center">
									{logoImage ? <Image src={logoImage.url} alt={logoAlt} width={logoWidth} height={logoWidth / logoImage.dimensions.width / logoImage.dimensions.height} /> : <div className="p-4 text-center bg-black/5 text-xs w-full">Image not set</div>}
								</div>
							</div>;
      })}
			</div>
		</div>;
});